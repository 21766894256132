
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
// @ts-ignore: Vuetify error
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import { CKEditorConfig } from '@/modules/core/ckeditor/ckeditor'
import { CKEditorImageConfig } from '@/modules/core/ckeditor/ckeditor-image'
import {MultipleChoiceAnswer} from "@/modules/question/models/MultipleChoiceAnswer";
import {MultipleChoiceQuestion, Hint} from "@/modules/question/models/MultipleChoiceQuestion";
import {EssayQuestion} from "@/modules/question/models/EssayQuestion";

@Component<QuestionForm>({
    watch: {
        questionReference: {
            handler: function (value: Object, oldValue: Object) {
                this.setInitialValues();
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        gradeValidate: function () {
            if(this.question && this.question.answers && this.question.type === 'multichoice') {
                let fieldsWithRightAnswer = this.question.answers.filter(answer => {
                    return answer.grade.toString() === '1';
                });

                if(this.question.single) {
                    return [fieldsWithRightAnswer.length === 1 || 'Es darf nur eine Antwort richtig sein'];
                }
                return [fieldsWithRightAnswer.length > 0 || 'Mindestens eine Antwort sollte richtig sein'];
            }
        },
    }
})
export default class QuestionForm extends Vue {
    @Prop({default: undefined}) questionReference: EssayQuestion | MultipleChoiceQuestion | undefined
    @Prop({default: ''}) type: string | undefined

    question: EssayQuestion | MultipleChoiceQuestion | null = null

    nameRules = [
        (v: string) => !!v || "Sie müssen hier einen Wert eingeben.",
        (v: string) => (v && v.length <= 250) || "Höchstens 255 Buchstaben.",
    ]

    defaultMarkRules = [
        (v: string) => !!v || "Sie müssen hier einen Wert eingeben."
    ]

    editor = ClassicEditor
    textEditorConfig = {
        plugins: CKEditorConfig.plugins,
        toolbar: CKEditorConfig.toolbar,
        placeholder: 'Text...'
    }

    editorConfigLoaded = false

    gradeOptions = [
        {text: "Falsch", value: 0},
        {text: "Richtig", value: 1},
    ]

    singleOptions = [
        {text: "Nur eine Antwort erlauben", value: true},
        {text: "Mehrere Antworten erlauben", value: false}
    ]

    requiresTextOptions = [
        {text: "Teilnehmer/in muss Text eingeben", value: 1},
        {text: "Texteingebe ist optional", value: 0},
    ]

    valid = true
    panels: number = 3;
    openedPanels = []

    openAllPanels () {
        if (this.panels !== null) {
            this.openedPanels = this.getPanelArr(this.panels);
        }
    }

    closeAllPanels () {
        this.openedPanels = [];
    }

    getPanelArr(panelsInt: number) : any {
        let panelArr: any = [];

        while (panelsInt !== 0) {
            panelArr[panelsInt-1] = panelsInt-1;
            panelsInt--;
        }

        return panelArr;
    }

    validate() {
        // @ts-ignore: Vuetify error
        if(this.question && this.question.type === 'multichoice' && this.$refs.form.validate() && this.gradeValidate) {
            this.$emit('send', this.question);
        } else {
            // @ts-ignore: Vuetify error
            if(this.$refs.form.validate()) {
                this.$emit('send', this.question);
            }
        }
    }

    reset() {
        if (this.questionReference !== undefined && this.questionReference !== null) {
            this.setInitialValues()
        } else {
            // @ts-ignore: Vuetify error
            this.$refs.form.reset()
        }
    }

    setInitialValues() {
        if (this.question !== null) {
            if (this.questionReference !== undefined && this.questionReference !== null) {
                this.question.type = this.questionReference.type
                this.question.name = this.questionReference.name
                this.question.text = this.questionReference.text
                this.question.defaultMark = this.questionReference.defaultMark

                if(this.question.type === "multichoice" && this.questionReference.type === "multichoice") {
                    this.question.text_draft_item_id = this.questionReference.text_draft_item_id
                    this.question.textEditorConfig = {
                        plugins: CKEditorImageConfig.plugins,
                        toolbar: CKEditorImageConfig.toolbar,
                        placeholder: 'Text...',
                        image: {
                            draftItemId: this.question.text_draft_item_id
                        }
                    }
                }

                if (this.questionReference.answers) {
                    let answerArray = []
                    for (let answer of this.questionReference.answers) {
                        let answerObject = {
                            id: answer.id,
                            answer: answer.answer,
                            answer_draft_item_id: answer.answer_draft_item_id,
                            answerEditor: {
                                plugins: CKEditorImageConfig.plugins,
                                toolbar: CKEditorImageConfig.toolbar,
                                placeholder: 'Text...',
                                image: {
                                    draftItemId: answer.answer_draft_item_id
                                }
                            },
                            grade: answer.grade,
                            feedback: answer.feedback
                        }
                        answerArray.push(answerObject)
                    }
                    this.question.answers = answerArray
                }

                if(this.question.type === "essay" && this.questionReference.type === "essay") {
                    this.question.requireText = this.questionReference.requireText
                }
                if(this.question.type === "multichoice" && this.question.hints.length === 0) {
                    this.addNewHint();
                }
                if(this.question.type === "multichoice" && this.questionReference.type === "multichoice") {
                    this.question.single = this.questionReference.single
                    this.question.shuffleAnswers = this.questionReference.shuffleAnswers
                }
            } else {
                // default answers for new questions of type
                if(this.question.type === "multichoice") {
                    this.question.answers = [];
                    for(let i = 0; i < 3; i++) {
                        this.addNewAnswer();
                    }
                    this.addNewHint();
                }
            }
        }
    }

    addNewHint() {
        if(this.question && this.question.type === 'multichoice') {
            let hint = {
                hint: '',
                clearWrong: false,
                showCorrectNumber: false
            } as Hint
            this.question.hints.push(hint);
        }
    }

    addNewAnswer() {
        let answer = {
            answer: '',
            answer_draft_item_id: 0,
            answerEditor: {
                plugins: CKEditorImageConfig.plugins,
                toolbar: CKEditorImageConfig.toolbar,
                placeholder: 'Text...',
                image: {
                    draftItemId: 0
                }
            },
            grade: '',
            feedback: ''
        } as MultipleChoiceAnswer
        if(this.question && this.question.answers && this.question.type === 'multichoice') {
            this.question.answers.push(answer);
        }
    }

    async created() {
        if (this.question === null && this.type === 'essay') {
            this.question = {
                type: 'essay',
                name: '',
                text: '',
                defaultMark: 1,
                answers: null,
                requireText: 1
            } as EssayQuestion
        }

        if (this.question === null && this.type === 'multichoice') {
            this.question = {
                type: 'multichoice',
                name: '',
                answer: '',
                text: '',
                text_draft_item_id: 0,
                textEditorConfig: {
                    plugins: CKEditorImageConfig.plugins,
                    toolbar: CKEditorImageConfig.toolbar,
                    placeholder: 'Text...',
                    image: {
                        draftItemId: 0
                    }
                },
                defaultMark: 1,
                answers: [],
                single: false,
                shuffleAnswers: false,
                hints: []

            } as MultipleChoiceQuestion
        }

        this.setInitialValues()

        if (this.panels !== null) {
            this.openedPanels = this.getPanelArr(this.panels)
        }

        await this.sleep(2000);
        this.editorConfigLoaded = true
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
